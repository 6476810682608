.content {
  height: calc(100vh - 65px);
  width: 100vw;
}

.formContent {
  padding: 4vw;
}

.textField {
  margin-top: 12px !important;
}
